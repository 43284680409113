import { useNavigate } from "react-router-dom";
import { Radar } from "react-chartjs-2";
import {
  Chart,
  RadialLinearScale,
  RadarController,
  PolarAreaController,
  PointElement,
  LineElement,
} from "chart.js";
import { useResize } from "../../Hooks/useResize";
import "./Result.scss";

Chart.register(
  RadialLinearScale,
  RadarController,
  PolarAreaController,
  PointElement,
  LineElement
);

const Result = () => {
  const navigate = useNavigate();
  const emptyChart = ["", "", "", "", "", "", "", "", "", "", ""];
  const chartData = JSON.parse(localStorage.getItem("result_data"));

  const labelInfo = [
    "Амбиции",
    "Безопасность",
    "Благополучие",
    "Досуг",
    "Духовное",
    "Занятость",
    "Здоровье",
    "Коммуникации",
    "Материальное",
    "Окружение",
    "Статус",
  ];

  const chartBackground = [
    "#8ebfff",
    "#00A6B4",
    "#50c6ca",
    "#4d7fbe",
    "#3b71b86e",
    "#004094",
    "#390094",
  ];

  const chartHover = [
    "#3b71b88f",
    "#00a5b47a",
    "#50c6ca8c",
    "#4d80be80",
    "#3b71b82d",
    "#00409475",
    "3900946b",
    "b002db6b",
  ];

  const width = useResize();

  const options = {
    scales: {
      r: {
        pointLabels: {
          display: false,
        },
      },
    },
  };

  const radarChartData = {
    labels: labelInfo,
    datasets: [
      {
        backgroundColor: chartBackground,
        borderColor: "#8ebfff",
        fill: false,
        hoverBackgroundColor: chartHover,
        data: chartData ? Object.values(chartData.collect) : emptyChart,
      },
    ],
  };

  return (
    <div className="WrapperResult">
      <div className="ResultHeader">
        <h1>Результаты тестирования</h1>
      </div>
      <div className="ResultBox">
        <div className="ResultContent">
          <div>
            <div>
              На данной диаграмме представлены ключевые пункты, выбранные вами в
              ходе раскидывания монеток. Каждый пункт отражает аспекты,
              обладающие наибольшей ценностьюдля вас. Визуально вы можете
              оценить, что является приоритетным, а также выявить области,
              требующие дополнительного внимания и развития.
            </div>
            <div className="RadarRules">
              Используйте эту диаграмму как инструмент для определения своих
              сильных сторон и тех направлений, которые можно улучшить или
              подтянуть в будущем.
            </div>
          </div>
          <div className="ButtonBox">
            {/* <div className="DownloadButton" onClick={getPDF}>
              Сохранить результат
            </div> */}
            <div
              className="NextButton"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/PrizeDraw");
              }}
            >
              Участвовать в розыгрыше
            </div>
          </div>
        </div>
        <div className="ChartBox">
          {" "}
          <div className="pieChart">
            {" "}
            <Radar
              id="chart"
              data={radarChartData}
              // options={
              //   width.width > 500
              //     ? {
              //         legend: {
              //           display: false,
              //         },
              //         scale: {
              //           ticks: { display: false },
              //         },
              //       }
              //     : {
              //         legend: {
              //           display: false,
              //         },
              //         scales: {
              //           r: {
              //             pointLabels: {
              //               display: false,
              //             },
              //           },
              //         },
              //       }
              // }
            />
          </div>
        </div>
      </div>
      <div className="AgainBox">
        <div className="AgainButton" onClick={() => navigate("/TokenData")}>
          Попробовать ещё раз
        </div>
      </div>
    </div>
  );
};

export default Result;
