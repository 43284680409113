import { Link, useNavigate } from "react-router-dom";
import "./Rules.scss";

const Rules = () => {
  const navigate = useNavigate();

  return (
    <div className="WrapperRules">
      <div className="RulesHeader">
        <h1>Правила тестирования</h1>
        <span>
          Представьте себе идеальную жизнь. Ту, что приносит максимальное
          счастье и полную удовлетворенность. Какая она? Организуйте себе свою
          мечту.
        </span>
      </div>
      <div className="RulesBox">
        <div className="RuleContent">
          <h2>1. Исходный ресурс</h2>
          <span>
            У вас есть <b>100 единиц уникального универсального ресурса</b>,
            который может буквально все. Больше нет, но что особенно важно —{" "}
            <b>и не будет.</b>
          </span>
        </div>
        <div className="RuleContent">
          <h2>2. Что нужно сделать?</h2>{" "}
          <span>
            <b>Распределите</b> этот ресурс по ячейкам так,{" "}
            <b>как считаете нужным</b>. Сколько будет вложено в качество,{" "}
            <b>настолько оно будет организовано</b>.
          </span>
        </div>
        <div className="RuleContent">
          <h2>3. Сколько ячеек?</h2>{" "}
          <span>
            11 групп, в каждой по 4 качества и дополнительное поле для прочего.
            Если чего-то важного для вас нет в ячейках,{" "}
            <b>оставьте ресурс незадействованным</b>.
          </span>
        </div>
        <div className="RuleContent">
          <h2>4. Что важно помнить?</h2>{" "}
          <span>
            <b>Вы инвестируете в то</b>, без чего ваша жизнь{" "}
            <b>не будет качественной</b>. <b>Никаких ограничений</b> — все так,
            как Вам понимается, хочется или кажется.
          </span>
        </div>
      </div>{" "}
      <div className="wrapperBtn">
        <button
          className="btnActive btnActiveBig"
          onClick={() => navigate("/TokenData")}
        >
          Начать тестирование
        </button>
      </div>
    </div>
  );
};

export default Rules;
