import { useNavigate } from "react-router-dom";

import { useResize } from "../../Hooks/useResize";
import "./PrizeDraw.scss";
import prizeList from "../../prizeList";
import { useEffect, useState } from "react";
import Send from "../../Api/Send";

const PrizeDraw = () => {
  const navigate = useNavigate();
  const [is_popUp, setIsPopUP] = useState(false);
  const tokenData = JSON.parse(localStorage.getItem("token_data"));
  const [count, setCount] = useState(0);
  const [rawList, setRawList] = useState(
    prizeList.map((prize) => {
      return { ...prize, chosen: false };
    }) || []
  );

  function selectPrize(item) {
    const updatedList = rawList.map((prize) =>
      prize.prize === item
        ? prize.chosen === false && count < 2
          ? { ...prize, chosen: true }
          : { ...prize, chosen: false }
        : prize
    );

    setRawList(updatedList);
  }

  async function finishPrize() {
    if (count === 2) {
      let list = rawList.filter((prize) => prize.chosen);

      console.log(
        list.map((item) => {
          return item.prize;
        })
      );

      const result = await Send.sendAll({
        ...tokenData,
        prizes: list.map((item) => {
          return item.prize;
        }),
      });

      if (result.message === "IS STOP") {
        document.body.style.overflowY = "hidden";
        window.scrollTo(0, 0);
        setIsPopUP(true);
      }
    }
  }

  useEffect(() => {
    let count = 0;

    rawList.map((prize) => {
      if (prize.chosen) {
        count++;
      }
    });

    setCount(count);
  }, [rawList]);

  return (
    <div className="WrapperPrize">
      <div className="PrizeHeader">
        <h1>Розыгрыш призов</h1>
        <span>
          Уважаемый участник! Вы можете выбрать 2 из 10 предложенных призов в
          розыгрыше, в котором будете участвовать
        </span>
      </div>
      <div className="PrizeBox">
        {rawList.map((prize) => {
          return (
            <div
              className={
                prize.chosen ? "PrizeContent PrizeChosen" : "PrizeContent"
              }
              onClick={() => selectPrize(prize.prize)}
            >
              <div
                className={
                  prize.place === "Технопарк"
                    ? "PrizePlace TechnoPark"
                    : "PrizePlace"
                }
              >
                {prize.place}
              </div>
              <div className="Prize">{prize.prize}</div>
              {prize.url && (
                <div
                  className="URLButton"
                  onClick={(e) => {
                    if (e.stopPropagation) e.stopPropagation();
                    window.open(prize.url);
                  }}
                >
                  Подробнее
                </div>
              )}
            </div>
          );
        })}
      </div>{" "}
      <div className="ButtonFinishBox">
        <div
          className={count < 2 ? "NotChosen" : "NextButton"}
          onClick={finishPrize}
        >
          Участвовать в розыгрыше
        </div>
      </div>
      {is_popUp && (
        <>
          <div className="WrapperPopUp">
            <div
              className="close"
              onClick={() => {
                setIsPopUP(false);
                document.body.style.overflowY = "auto";
                navigate("/");
              }}
            ></div>
            <h1>Благодарим Вас за участие!</h1>
            <div className="userID">
              <h1>{tokenData.user_id}</h1>
            </div>
            <label>
              Это Ваш личный уникальный номер участника опроса. На основании
              данного номера Вы становитесь участником розыгрыша призов.
              Определение победителей будет проведено 01.01.24. путем случайного
              выбора номеров участников.
            </label>
            <div className="PrizeURLBox">
              <div>
                Розыгрыш призов будет проводиться в прямом эфире на официальных
                каналах РГСУ на платформах:{" "}
                <span
                  onClick={() => window.open("https://vk.com/rgsu_official")}
                >
                  VK
                </span>{" "}
                и{" "}
                <span
                  onClick={() =>
                    window.open("https://rutube.ru/channel/24733751/")
                  }
                >
                  Rutube
                </span>
              </div>
            </div>
          </div>
          <div
            className="ScreenBlack"
            onClick={() => {
              setIsPopUP(false);
              document.body.style.overflowY = "auto";
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default PrizeDraw;
