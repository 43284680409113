import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import dataText from "../../dataText";
import Send from "../../Api/Send";

import "./TokenData.scss";

const TokenData = () => {
  const [load, setLoad] = useState(false);
  const [dataDefault, setDataDefault] = useState([]);
  const [newData, setNewData] = useState([]);
  const [userId, setUserId] = useState("");
  const [Createdate, setCreatedate] = useState("");
  const userFormData = JSON.parse(localStorage.getItem("reg_info")) || null;

  const navigate = useNavigate();

  const [currentCount, setCurrnetCount] = useState(0);
  const [totalBalance, setTotalBalance] = useState(100);

  const [is_popUp, setIsPopUP] = useState(false);
  const [is_popUpInformation, setIs_popUpInformation] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [selectedHeader, setSelectedHeader] = useState("");
  const [selectedID, setSelectedID] = useState("");

  //Timer
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      try {
        const data = await Send.getAll();

        let newData = {};
        let usedDublicateDAta = [];
        const dataBalance = data.balances;

        for (let i = 0; i < dataBalance.length; i++) {
          if (newData.length === 0) {
            newData[dataBalance[i].gr] = {
              data: [dataBalance[i].name],
              id: [dataBalance[i].id],
              balance: [dataBalance[i].balance],
            };
            usedDublicateDAta.push(dataBalance[i].gr);
            continue;
          } else {
            if (newData[dataBalance[i].gr] !== undefined) {
              newData[dataBalance[i].gr]["data"].push(dataBalance[i].name);
              newData[dataBalance[i].gr]["id"].push(dataBalance[i].id);
              newData[dataBalance[i].gr]["balance"].push(
                dataBalance[i].balance
              );
            } else {
              newData[dataBalance[i].gr] = {
                data: [dataBalance[i].name],
                id: [dataBalance[i].id],
                balance: [dataBalance[i].balance],
              };
              usedDublicateDAta.push(dataBalance[i].gr);
            }
          }
        }

        setDataDefault(data.balances);
        setNewData(newData);
        setUserId(data.user_id);
        setCreatedate(data.createdate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoad(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (userFormData === null) {
      navigate("/");
    }
  }, [userFormData, navigate]);

  useEffect(() => {
    let interval = null;

    if (isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isPaused]);

  const selecteToken = (token, column) => {
    setIsPopUP(true);
    document.body.style.overflowY = "auto";

    for (let item in dataDefault) {
      if (dataDefault[item]["id"] === token) {
        setSelectedID(token);
        setSelectedHeader(dataDefault[item].name);
        setSelectedBlock(dataDefault[item]);
        setSelectedText(dataText[dataDefault[item].id]);
        setCurrnetCount(column["balance"][column["id"].indexOf(token)]);
        break;
      }
    }
  };

  const btnDeMinus = (digit) => {
    let digitNew = [];

    Object.keys(newData).map((key) => {
      if (selectedBlock["gr"] === key) {
        digitNew = newData[key].balance;
        newData[key].data.map((item, index) => {
          if (
            newData[key].id[index] === selectedBlock["id"] &&
            digitNew[index] === 0
          ) {
            alert("Не хватает монет");
          }
          if (
            newData[key].id[index] === selectedBlock["id"] &&
            digitNew[index] > 0
          ) {
            setTotalBalance(
              digitNew[index] < 10 && digit === 10
                ? totalBalance + digitNew[index]
                : totalBalance + digit
            );
            digitNew[index] =
              digitNew[index] < 10 && digit === 10
                ? 0
                : digitNew[index] - digit;

            newData[key].balance = digitNew;
            setCurrnetCount(digitNew[index]);
            setDataDefault((prevBalances) => {
              return prevBalances.map((item) => {
                return item.id === newData[key].id[index]
                  ? { ...item, balance: digitNew[index] }
                  : item;
              });
            });
          }
          return digitNew[index];
        });
      }
      return key;
    });
  };

  const btnDePlus = (digit) => {
    let digitNew = [];

    if (totalBalance === 0) {
      alert("Не хватает монет");
    }

    Object.keys(newData).map((key) => {
      if (selectedBlock["gr"] === key) {
        digitNew = newData[key].balance;
        newData[key].data.map((item, index) => {
          if (
            newData[key].id[index] === selectedBlock["id"] &&
            digitNew[index] < 100
          ) {
            setTotalBalance(totalBalance >= digit ? totalBalance - digit : 0);
            digitNew[index] =
              totalBalance >= digit
                ? digitNew[index] + digit
                : digitNew[index] + totalBalance;
            newData[key].balance = digitNew;
            setCurrnetCount(digitNew[index]);
            setDataDefault((prevBalances) => {
              return prevBalances.map((item) => {
                return item.id === newData[key].id[index]
                  ? { ...item, balance: digitNew[index] }
                  : item;
              });
            });
          }
          return digitNew[index];
        });
      }
      return key;
    });
  };

  const sendToken = async () => {
    setIsPaused(true);

    const distributionInfo = {
      step: 1,
      user_id: userId,
      total_balance: totalBalance,
      balances: dataDefault,
      userData: userFormData,
      createdate: Createdate,
      counter: time,
      questions: selectedQuestions,
    };

    try {
      const result = await Send.sendAll(distributionInfo);

      localStorage.setItem("token_data", JSON.stringify(distributionInfo));

      if (result.message === "IS STOP") {
        localStorage.setItem("result_data", JSON.stringify(result));
        navigate("/Result");
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const applyResult = () => {
    setIsPopUP(false);
    document.body.style.overflowY = "auto";
  };

  const openAnswer = (id, name) => {
    setSelectedQuestions((prevData) => [...prevData, { [id]: dataText[id] }]);
    setSelectedHeader(name);
    setSelectedText(dataText[id]);
    setSelectedID(id);
    setIs_popUpInformation(true);
  };

  useEffect(() => {
    let rawBalance = 0;

    dataDefault.forEach((item) => {
      rawBalance += item.balance;
    });

    let rawTotal = 100 - rawBalance;

    setTotalBalance(rawTotal);
  }, [dataDefault]);

  return (
    <div className="mainWrapperContent">
      {is_popUp === true && is_popUpInformation === false ? (
        <>
          <div className="WrapperPopUp">
            <div
              className="close"
              onClick={() => {
                setIsPopUP(false);
                document.body.style.overflowY = "auto";
              }}
            ></div>
            <h1 className="WrapperPopUpHeader">{selectedBlock["name"]}</h1>
            <p>{selectedText}</p>
            <div className="PopUpContentWrapper">
              <div className="PopUpContentWrapperBtnBlocks">
                <p className="PopUpContentText">Минус</p>
                <button className="btnCircle" onClick={() => btnDeMinus(1)}>
                  1
                </button>
                <button className="btnCircle" onClick={() => btnDeMinus(10)}>
                  10
                </button>
              </div>
              <div className="PopUpContentCounter">{currentCount}</div>
              <div className="PopUpContentWrapperBtnBlocks">
                <p className="PopUpContentText">Плюс</p>
                <button className="btnCircle" onClick={() => btnDePlus(1)}>
                  1
                </button>
                <button className="btnCircle" onClick={() => btnDePlus(10)}>
                  10
                </button>
              </div>
            </div>
            <button id="dopActive" className="btnActive" onClick={applyResult}>
              Продолжить
            </button>
          </div>
          <div
            className="ScreenBlack"
            onClick={() => {
              setIsPopUP(false);
              document.body.style.overflowY = "auto";
            }}
          ></div>
        </>
      ) : null}

      {is_popUpInformation === true ? (
        <>
          <div className="WrapperPopUp">
            <div
              className="close"
              onClick={() => setIs_popUpInformation(false)}
            ></div>
            <h1>{selectedHeader}</h1>
            <p>{selectedText}</p>
          </div>
          <div
            className="ScreenBlack"
            onClick={() => setIs_popUpInformation(false)}
          ></div>
        </>
      ) : null}
      <div className="WrapperHeader">
        <div className="AmountBox">
          Остаток: <p> {totalBalance}</p>
        </div>
        <button
          className={totalBalance < 100 ? "btnActive" : "btn"}
          style={{ marginLeft: "25px" }}
          onClick={() => sendToken()}
          disabled={totalBalance < 100 ? false : true}
        >
          Отправить
        </button>
      </div>
      {load ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%",marginTop:'20px' }}
        >
          {" "}
          <ReactLoading
            type="spinningBubbles"
            color="black"
            height={"50px"}
            width={"50px"}
          />
        </div>
      ) : (
        <div className="TokenWrapper">
          {Object.keys(newData).map((item, i) => {
            return (
              <div key={newData[item].id} className="wrapperTokenColumns">
                <div className="wrapperTokenRow">
                  {" "}
                  <div className="wrapperSimbolQuestionArticle">
                    <p
                      className="simbolQuestion"
                      onClick={() => openAnswer(item, item)}
                    >
                      !
                    </p>
                    <p
                      className="ArticleText"
                      onClick={() => openAnswer(item, item)}
                    >
                      {item}
                    </p>
                  </div>
                  {newData[item].data.map((token, index) => {
                    return (
                      <div
                        key={index}
                        className="TokenBlock"
                        onClick={() =>
                          selecteToken(newData[item].id[index], newData[item])
                        }
                        style={{
                          backgroundColor:
                            newData[item].balance[index] > 0
                              ? "#005AB326"
                              : "transparent",
                        }}
                      >
                        <div className="TokenBlockText">{token}</div>

                        <p
                          style={{
                            display:
                              newData[item].balance[index] > 0
                                ? "block"
                                : "none",
                          }}
                        >
                          {newData[item].balance[index]}
                        </p>
                        <div className="simbolQuestionWrapper">
                          {/* <p className="simbolQuestion" onClick={() => openAnswer(newData[item].id[index])}>?</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TokenData;
